import images from './images';

const wines = [
  {
    title: 'Chapel Hill Shiraz',
    price: '$56',
    tags: 'AU | Bottle',
  },
  {
    title: 'Catena Malbee',
    price: '$59',
    tags: 'AU | Bottle',
  },
  {
    title: 'La Vieillw Rose',
    price: '$44',
    tags: 'FR | 750 ml',
  },
  {
    title: 'Rhino Pale Ale',
    price: '$31',
    tags: 'CA | 750 ml',
  },
  {
    title: 'Irish Guinness',
    price: '$26',
    tags: 'IE | 750 ml',
  },
];

const cocktails = [
  {
    title: 'Aperol Sprtiz',
    price: '$20',
    tags: 'Aperol | Villa Marchesi prosecco | soda | 30 ml',
  },
  {
    title: "Dark 'N' Stormy",
    price: '$16',
    tags: 'Dark rum | Ginger beer | Slice of lime',
  },
  {
    title: 'Daiquiri',
    price: '$10',
    tags: 'Rum | Citrus juice | Sugar',
  },
  {
    title: 'Old Fashioned',
    price: '$31',
    tags: 'Bourbon | Brown sugar | Angostura Bitters',
  },
  {
    title: 'Negroni',
    price: '$26',
    tags: 'Gin | Sweet Vermouth | Campari | Orange garnish',
  },
];

const awards = [
  {
    imgUrl: images.award02,
    title: 'Bib Gourmond',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur.',
  },
  {
    imgUrl: images.award01,
    title: 'Rising Star',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur.',
  },
  {
    imgUrl: images.award05,
    title: 'AA Hospitality',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur.',
  },
  {
    imgUrl: images.award03,
    title: 'Outstanding Chef',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur.',
  },
];

const bobateas = [
  {
    title: 'Classic Milk Tea',
    price: '$5',
    tags: 'Black tea | Milk | Tapioca pearls | 500 ml',
  },
  {
    title: 'Taro Milk Tea',
    price: '$6',
    tags: 'Taro flavor | Milk | Tapioca pearls | 500 ml',
  },
  {
    title: 'Matcha Milk Tea',
    price: '$5.50',
    tags: 'Matcha | Milk | Tapioca pearls | 500 ml',
  },
  {
    title: 'Mango Fruit Tea',
    price: '$6.50',
    tags: 'Mango flavor | Fruit pieces | Jelly | 500 ml',
  },
  {
    title: 'Strawberry Yakult',
    price: '$6',
    tags: 'Strawberry | Yakult | Aloe Vera | 500 ml',
  },
  {
    title: 'Brown Sugar Milk Tea',
    price: '$6.50',
    tags: 'Brown sugar syrup | Milk | Tapioca pearls | 500 ml',
  },
  {
    title: 'Lychee Oolong Tea',
    price: '$5.50',
    tags: 'Oolong tea | Lychee flavor | Jelly | 500 ml',
  },
];
const cakes = [
  {
    title: 'Velvety Red Velvet',
    price: '$40',
    tags: 'Red velvet cake | Cream cheese frosting | 8-inch round',
  },
  {
    title: 'Classic New York Cheesecake',
    price: '$50',
    tags: 'Cream cheese | Graham cracker crust | 9-inch round',
  },
  {
    title: 'Decadent Chocolate Fudge',
    price: '$45',
    tags: 'Chocolate cake | Fudge icing | Dark chocolate shavings | 8-inch round',
  },
  {
    title: 'Luscious Lemon Drizzle',
    price: '$38',
    tags: 'Lemon cake | Lemon icing | Candied lemon peel | 8-inch round',
  },
  {
    title: 'Berry Berry Strawberry Shortcake',
    price: '$42',
    tags: 'Sponge cake | Whipped cream | Fresh strawberries | 8-inch round',
  },
  {
    title: 'Salted Caramel Delight',
    price: '$46',
    tags: 'Caramel cake | Salted caramel frosting | Caramel drizzle | 8-inch round',
  },
];

export default { wines, cocktails, awards, bobateas, cakes };

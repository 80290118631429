// import React from 'react';
// import SubHeading from '../../components/SubHeading/SubHeading';
// import { images } from '../../constants';

// import './Header.css';

// const Header = () => (
//   <div className='app__header app_wrapper section__padding' id='home'>
//     <div className='app__wrapper info'>
//       <SubHeading title="Hi, Welcome"/>
//         <h1 className='app__header-h1'>Xin's Restaurant Example</h1>
//         <p className="p__opensans" style={{ margin: '2rem 0' }}>Sit tellus lobortis sed senectus vivamus molestie. Condimentum volutpat morbi facilisis quam scelerisque sapien. Et, penatibus aliquam amet tellus </p>
//     </div>
//     <div className='app__wrapper_img'>
//     </div>
//   </div>
// );

// export default Header;
import React from 'react';

import { SubHeading } from '../../components';
import { images } from '../../constants';
import './Header.css';

const Header = () => (
  <div className="app__header app__wrapper section__padding" id="home">
    <div className="app__wrapper_info1">
      {/* <SubHeading title="Hi, Welcome" />
      <h1 className="app__header-h1">Amber's Dream Restuarant Website </h1>
      <p className="p__opensans" style={{ margin: '2rem 0' }}>Sit tellus lobortis sed senectus vivamus molestie. Condimentum volutpat morbi facilisis quam scelerisque sapien. Et, penatibus aliquam amet tellus </p> */}
      <button type="button" className="custom__button">Explore Menu</button>
    </div>

    {/* <div className="app__wrapper_img">
      <img src={images.welcome} alt="header_img" />
    </div> */}
  </div>
);

export default Header;
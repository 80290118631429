import React from 'react';
import { BsFillPlayFill, BsPauseFill } from 'react-icons/bs';

import { meal } from '../../constants';

import './Intro.css';

const Intro = () => {
  const [playing, setPlaying] = React.useState(false);
  const videoRef = React.useRef();
  const handleVideo = () => {
    setPlaying((prePlayvideo)=> !prePlayvideo);
    playing ? videoRef.current.pause() : videoRef.current.play();
    
  }
  return (
    <div className='app__video'>
      <video
      ref={videoRef}
      src={meal}
      type="video/mp4"
      loop
      controls={false}
      muted
      />
      <div className='app__video-overlay flex__center'>
        <div className='app__video-overlay__circle flex__center' onClick={handleVideo}>
          {playing ?  (<BsPauseFill color="#fff" fontSize={30} />) :  (<BsFillPlayFill color="#fff" fontSize={30} />)
          }
        </div>
      </div>
    </div>
  )
};

export default Intro;

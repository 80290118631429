import React from 'react';

import './SpecialMenu.css';
import {images, data} from '../../constants'
import { SubHeading, MenuItem } from '../../components';

const SpecialMenu = () => (
  <div className="app__specialMenu flex__center section__padding" id="menu">
    <div className="app__specialMenu-title">
      <SubHeading title="What do u want to drink?" />
      <h1 className="headtext__cormorant">Boba Tea && Cake Menu</h1>
    </div>

    <div className="app__specialMenu-menu">
      <div className="app__specialMenu-menu_boba  flex__center">
        <p className="app__specialMenu-menu_heading">Boba Tea</p>
        <div className="app__specialMenu_menu_items">
          {data.bobateas.map((bobatea, index) => (
            <MenuItem key={bobatea.title+index} title={bobatea.title} price={bobatea.price} tags={bobatea.tags}/>
          ))}
        </div>
      </div>

      <div className="app__specialMenu-menu_img">
        <div className="app__specialMenu-menu_img1">
          <img src={images.bobacake} />
        </div>
        <div className="app__specialMenu-menu_img2">
          <img src={images.bobatea}/>
        </div>
      </div>


      <div className="app__specialMenu-menu_cake  flex__center">
        <p className="app__specialMenu-menu_heading">Cakes</p>
        <div className="app__specialMenu_menu_items">
          {data.cakes.map((cake, index) => (
            <MenuItem key={cake.title+index} title={cake.title} price={cake.price} tags={cake.tags}/>
          ))}
        </div>
      </div>
    </div>
    <div>
      <button type="button" className="custom__button">View More</button>
    </div>
  </div>
);

export default SpecialMenu;
